import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Modal, Spinner } from 'react-bootstrap'
import MarkdownView from 'react-showdown'

const Ecovalencias = ( {ecovalencias, contenidoModal=null, loading=false} ) => {
    //Modal ecovalencias
    const [showEco, setShowEco] = useState(false);
    const handleCloseEco = () => setShowEco(false);
    const handleShowEco = () => setShowEco(true);
    console.debug(contenidoModal)
    const colorBase = (contenidoModal)?`${contenidoModal.color_texto}!important`:"#fff"
    return (
        <div className="ecovalencias d-flex align-content-stretch flex-wrap p-0">
            <div className="tabla-header w-100 pb-0">
                <h6 >Tus Ecovalencias</h6>
                <h5 className="text-uppercase">Tu reciclaje hasta la fecha equivale a</h5>
            </div>
            
            <div className="d-flex flex-wrap w-100 p-2 border-b">
                <div className="ecovalencia col-sm-12 col-md-4 mb-2 p-1">
                    <div className="box-white rounded text-center">
                        <div className="w-100 icono p-1"><i className="fas fa-truck" /></div>
                        <div className="w-100 titulo p-1">Evitar contaminar con</div>
                        <div className="w-100 eco-valor">
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Contenedores") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})}
                            &nbsp;tarros
                        </div>
                        <div className="w-100 cuerpo p-1">de basura en el vertedero</div>
                        <div className="w-100 pie">
                            (
                                {(loading)?
                                <Spinner animation="grow" size="sm" />
                                :
                                Number(ecovalencias.filter(eco => (eco.nombre === "Metros cúbicos") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }&nbsp;mt<sup>3</sup>)
                        </div>
                    </div>
                </div>
                <div className="ecovalencia col-sm-12 col-md-4 mb-2 p-1">
                    <div className="box-white rounded text-center">
                        <div className="w-100 icono p-1"><i className="fas fa-lightbulb" /></div>
                        <div className="w-100 titulo p-1">Evitar el equivalente al<br/>consumo eléctrico de</div>
                        <div className="w-100 eco-valor">
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Hogares") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            &nbsp;hogares
                        </div>
                        <div className="w-100 cuerpo p-1"></div>
                        <div className="w-100 pie">
                            (
                                {(loading)?
                                <Spinner animation="grow" size="sm" />
                                :
                                Number(ecovalencias.filter(eco => (eco.nombre === "Energía") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                                }
                                &nbsp;KWh)
                        </div>
                    </div>
                </div>
                <div className="ecovalencia col-sm-12 col-md-4 mb-2 p-1">
                    <div className="box-white rounded text-center">
                        <div className="w-100 icono p-1"><i className="fas fa-car" /></div>
                        <div className="w-100 titulo p-1">Evitar la contaminación de</div>
                        <div className="w-100 eco-valor">
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Viajes") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            &nbsp;viajes
                        </div>
                        <div className="w-100 cuerpo p-1">ida y vuelta Stgo - La Serena</div>
                        <div className="w-100 pie">
                            (
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Combustible") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            &nbsp;lt de combustible)
                        </div>
                    </div>
                </div>
                <div className="ecovalencia col-sm-12 col-md-4 mb-2 p-1">
                    <div className="box-white rounded text-center">
                        <div className="w-100 icono p-1"><i className="fas fa-tree" /><i className="fas fa-tree" /></div>
                        <div className="w-100 titulo p-1">Evitar cortar</div>
                        <div className="w-100 eco-valor">
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Árboles") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            <br/>árboles
                        </div>
                        <div className="w-100 cuerpo p-1"></div>
                        <div className="w-100 pie"></div>
                    </div>
                </div>
                <div className="ecovalencia col-sm-12 col-md-4 mb-2 p-1">
                    <div className="box-white rounded text-center">
                        <div className="w-100 icono p-1"><i className="fas fa-bath" /></div>
                        <div className="w-100 titulo p-1">Evitar el equivalente al<br/>consumo de agua de</div>
                        <div className="w-100 eco-valor">
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Duchas") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            &nbsp;duchas
                        </div>
                        <div className="w-100 cuerpo p-1"></div>
                        <div className="w-100 pie">de 5 minutos (
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Agua") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            &nbsp;lts)
                        </div>
                    </div>
                </div>
                <div className="ecovalencia col-sm-12 col-md-4 mb-2 p-1">
                    <div className="box-white rounded text-center">
                        <div className="w-100 icono p-1"><i className="fas fa-car-side" /></div>
                        <div className="w-100 titulo p-1">Evitar la contaminación de</div>
                        <div className="w-100 eco-valor">
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "Viajes") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            &nbsp;viajes
                        </div>
                        <div className="w-100 cuerpo p-1">ida y vuelta Stgo - La Serena</div>
                        <div className="w-100 pie">(
                            {(loading)?
                            <Spinner animation="grow" size="sm" />
                            :
                            Number(ecovalencias.filter(eco => (eco.nombre === "CO2") )[0].suma).toLocaleString('es-CL', {maximumFractionDigits: 1})
                            }
                            &nbsp;Kg de CO<sup>2</sup>)
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="w-100 text-center text-uppercase pt-4 pb-4">
                <Link to="#" onClick={handleShowEco} className="text-white font-weight-bold"><u>¿Cómo obtenemos estas ecovalencias?</u></Link>
            </div>

            {(contenidoModal)?
            <Modal key="modECovalencia" className="modal-eco" show={showEco} onHide={handleCloseEco} size="md" centered style={{color:"#fff"}}>
                <Modal.Header className="pb-1" closeButton style={{background: contenidoModal.color_fondo, color: colorBase}}>
                </Modal.Header>
                <Modal.Body className="pt-1" style={{background:contenidoModal.color_fondo}}>
                    <div className="row perfil-modal">
                        <div className="col-sm-12 col-lg-2 text-center">
                            <i className={`fas fa-${contenidoModal.icono}`}></i>
                        </div>
                        <div className="col-sm-12 col-lg-10">
                            <h4 style={{color:contenidoModal.color_texto}}>{contenidoModal.titulo}</h4>
                        </div>
                    </div>
                    <div className="p-2" style={{color:contenidoModal.color_texto}}>
                        <MarkdownView markdown={contenidoModal.contenido} />
                    </div>
                </Modal.Body>
            </Modal>
            :
            ""
            }
        </div>
    );
};

export default Ecovalencias;