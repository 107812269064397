import React from 'react'
import { Link } from 'gatsby'
import { Button } from "react-bootstrap";
import Spinner from './Spinner'
import TablaBase from "../components/TablaBase"
import { download } from '../utils/utils'

const Informes = ({ data, loading=false}) => {
    const urlDashboard = '/app/dashboard'
    
    const rutaArchivoBase = process.env.STRAPI_URL

	const columns = [{
            dataField: 'id',
            text: 'ID',
            hidden: true
        },{
			dataField: 'year',
			text: 'Año',
			hidden: false
	  	},{
			dataField: 'month',
            text: 'Mes',
            classes: 'upper-first',
			hidden: false
	  	},{
			dataField: "",
			text: "Informe",
			formatter: (cellContent, row) => {
			return (
                <Link className="text-left" to={urlDashboard + '/' +  row.fecha} ><span className="text-uppercase font-weight-bold">Ir a periodo</span></Link>
			);
			}
	  }];
    return (
        <div>
            <div className="informes-tabla box-white">
                <div className="tabla-header pb-1">
                    <h6>Informes</h6>
                    <h5 className="text-uppercase">Mensuales</h5>
                    {/* <a href="http://localhost:8000/img/logo-gestionb.png" download="descarja.png">bajar</a> */}
                </div>
                
                {(loading)?
                <Spinner />
                :
                <TablaBase
                data={ data }
                columns={ columns }
                enCarga={ loading }
                busqueda={false}
                descarga={false}
                ocultarCantidadPorPagina={true}
                cantidadPorPagina={12}
                responsive={false}
                />
                }
            </div>
        </div>
    );
};

export default Informes;