import React from 'react'
import MarkdownView from 'react-showdown'

/**
 * Despliega un consejo aleatorio
 * @param {consejos} props
 * 
 */
const ConsejoVerde = (props) => {

    return (
        <div className="consejo">
            <div className="d-flex flex-row">
                <div className="cuerpo">
                    <h5 className=""><span className="fas fa-recycle"></span></h5>
                </div>
                <div className="cuerpo">
                    <h5 className="text-uppercase pt-2">Consejo Verde</h5>
                </div>
            </div>
            <div className="d-flex flex-row">
                <div className="cuerpo">
                    <div className="">
                        {!props.loading?
                            (props.consejos && props.consejos.length > 0 )?
                            <MarkdownView markdown={(props.consejos)?props.consejos[Math.floor(Math.random() * (props.consejos.length) ) ].consejo:""} />
                            :
                            "No se encontraron consejos en el sistema."
                        :
                        ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsejoVerde;