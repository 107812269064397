import React from 'react';
import { useSelector } from 'react-redux'
import Spinner from './Spinner'

const RetiroTabla = ({ data, periodo="Actual", loading=false}) => {
    return (
			<div>
				<div className="retiro-tabla">
					{(periodo && periodo !== "")?
					<div className="tabla-header">
						<h6>Tu reciclaje del periodo</h6>
						<h5>{periodo}</h5>
					</div>
					:
					""
					}
					{(loading)?
					<Spinner />
					:
					<table className="table">
						<tbody className="table-body">
							{
								(data)?
								data.map( val => 
									(          
									<tr key={val.id} className={(val.kilogramos)?"":"tabla-na"}>
										<td className="tabla-label">{ val.nombre === "Orgánicos" ? "Otros" : val.nombre }</td>
										<td className="tabla-value pr-3">{(val.kilogramos)?val.kilogramos.toLocaleString('es-CL', {maximumFractionDigits: 1}):"N/A"} {(val.kilogramos)?<span className="tabla-unit">KG</span>:""}</td>
									</tr>
									)
								)
								:
								""
							}
						</tbody>
							
						<tfoot className="tabla-footer">
							<tr>
								<td className="tabla-label p-1 pl-3 pr-3" colSpan={2}>
								<span className="float-left tabla-total-text text-uppercase">Total</span>
								{(data)?
								<span className="tabla-total float-right">{Number(data.map(cat=>cat.kilogramos).reduce(function (acc, val) { return acc + val; }, 0)).toLocaleString('es-CL', {maximumFractionDigits: 1})}
								<span className="tabla-unit">KG</span>
								</span>
								:
								""
								}
								</td>
							</tr>
						</tfoot>
					</table>
					}
				</div>
			</div>
    );
};

export default RetiroTabla;