import React from 'react';
import MarkdownView from 'react-showdown'
import { Carousel } from 'react-bootstrap'

const Comentarios = (props) => {
    return (
        <div className="comentario">
                <div className="d-flex flex-row tabla-header border-b pb-1">
                    <div className="cuerpo">
                    <h5 className="text-dark"><span className="fas fa-comment-alt"></span></h5>
                    </div>
                    <div className="cuerpo pt-3">
                        <h5 className="text-uppercase">Comentarios</h5>
                    </div>
                </div>
                <div className="d-flex flex-row">
                    {(!props.loading)?
                        (!props.imprimirInforme)?
                        <Carousel className="col-12" interval={3000} pause={false}>
                            {(!props.comentarios)?
                            ""
                            :
                            props.comentarios.map(comentario => (
                                <Carousel.Item key={comentario.id} className="p-3">
                                    <MarkdownView markdown={comentario.comentario} />
                                </Carousel.Item>
                                )
                            )
                            }
                            
                        </Carousel>
                        :
                        <>
                        {(props.comentarios)?
                            <ul>
                                {props.comentarios.map(comentario => (
                                    <li key={comentario.id}><MarkdownView markdown={comentario.comentario} /></li>
                                    )
                                )}
                            </ul>
                            :
                            ""
                        }
                        </>
                    :
                    ""
                    }
                </div>
                
                {/* <div className="d-flex flex-row">
                    {(!props.comentarios)?
                    ""
                    :
                    props.comentarios.map(comentario => (
                        <div key={comentario.id} className="">
                            <h6>{comentario.periodo}</h6>                                
                            <MarkdownView markdown={comentario.comentario} />
                        </div>
                        )
                    )
                    }
                </div> */}
            
        </div>
    );
};

export default Comentarios;