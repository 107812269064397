import React, { useState } from 'react'
import Spinner from './Spinner'
import { download } from '../utils/utils'
import { Button, Accordion, Card } from "react-bootstrap"

const Bibliografia = ({ data, periodo="Actual", loading=false}) => {
	const rutaArchivoBase = process.env.STRAPI_URL

	const [activeKey, setActive] = useState(false)
	
    return (
			<div className="bibliografia">
					
				{(loading)?
				<Spinner />
				:
				<Accordion>
					<Card>
						<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setActive(!activeKey)}>
						<div className="">
							<h6>Información</h6>
							<h5 className="text-uppercase">Bibliográfica <i className={(activeKey)?"fas fa-minus":"fas fa-plus"}></i></h5>
						</div>
						</Accordion.Toggle>
						<Accordion.Collapse eventKey="0">
							<Card.Body>
							<div className="container">
								<div className="row">
									{
									(data)?
									data.map(bibl =>(
										<div key={bibl.id} className="col-sm-12 col-md-3">
											<Button onClick={() => download(rutaArchivoBase+bibl.documento.url, bibl.documento.name)} className="btn-block" >
												<div className="d-flex align-items-start">														
													<i className="fas fa-file-alt bl-icono"></i>
													<div className="titulos">
														<span>{bibl.nombre}</span>
														<span>{bibl.documento.name}</span>
													</div>
												</div>
											</Button>
										</div>
									))
									:
									""
									}
								</div>
							</div>
							
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				</Accordion>
				}
			</div>
    );
};

export default Bibliografia;