import React, { useEffect, useState } from 'react';
import { Doughnut, Bar } from 'react-chartjs-2';
import Spinner from './Spinner'

const RetiroChartDonuts = ({ data, periodo = "", loading= false}) => {
    
    const backgroundColor= [
        '#1c4334',//Vidrio
        '#1465c2',//Cartón
        '#114075',//Papel
        '#fefd60',//PET
        '#b2b4b2',//Latas
        '#b4b325',//EPS
        '#2f2222' //Orgánicos
    ]
    const groupCat= [
        'Vidrio',
        'Cartón',
        'Papel',
        'PET',
        'Latas',
        'EPS',
        'Orgánicos'
    ]
    const base = {
            datasets: [{
                data: [],
                backgroundColor: backgroundColor
            }],
            labels: []
    }
        
    const options = {
        aspectRatio: 1,
        cutoutPercentage: 90,
        layout: {
            padding: {
                left: 5,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        legend: {
            display: false
        },
        tooltips: {
            titleAlign: 'center',
            titleFontColor: '#fff',
            titleFontSize: 14,
            titleFontStyle: "normal",
            bodyAlign: "right",
            bodyFontColor: '#fff',
            bodyFontSize: 22,
            bodyFontStyle: "bold",
            footerFontSize: 10,
            footerAlign: "center",
            footerFontStyle: "normal",
            cornerRadius: 0,
            displayColors: false,
            borderColor: '#fff',
            borderWidth: 1,
            // se puede personalizar aún mas el tooltip https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html#external-custom-tooltips
            custom: function(tooltipModel) {
                if (tooltipModel.opacity !== 0) {
                    const index = tooltipModel.dataPoints[0].index;
                    const color =  backgroundColor[index];
                    tooltipModel.backgroundColor = color;
                    // para color claro caso: PET(o algun otro)
                    if(index === 3){
                        tooltipModel.titleFontColor = "#737373";
                        tooltipModel.footerFontColor = "#737373";
                    }
                    
                };
          
            },
            callbacks: {
                title: function(tooltipItems, data) {
                    const dataset = data.datasets[tooltipItems.datasetIndex];
                    console.debug(tooltipItems)
                    return groupCat[tooltipItems[0].index] === "Orgánicos" ? "Otros" : groupCat[tooltipItems[0].index];
                },
                label: function(tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = dataset.data.reduce(function(previousValue, currentValue, currentIndex, array) {
                        return previousValue + currentValue;
                    });
                    const currentValue = dataset.data[tooltipItem.index];
                    const percentage =((currentValue/total) * 100).toLocaleString('es-CL', {maximumFractionDigits: 1})
                    return percentage + "% ";
                },
                labelTextColor: function(tooltipItem, chart) {
                    // para color claro caso: PET(o algun otro)
                    if(tooltipItem.index === 3){
                        return '#737373';
                    }else{
                        return "#fff";
                    }
                    
                },
                footer: function(tooltipItems, data) {
                    const dataset = data.datasets[0];
                    console.debug(data)
                    console.debug(dataset.data[tooltipItems[0].index])
                    if(dataset){
                        const currentValue = dataset.data[tooltipItems[0].index];     
                        return currentValue.toLocaleString('es-CL', {maximumFractionDigits: 1}) + " KG";
                    }
                }
            }
        }
    }
        
    const [resumenCategoriaPeriodo, setData] = useState({})

    useEffect(() => {
        let datacopy = Object.assign({}, base)
        datacopy.labels = data.map(cat => cat.nombre)
        datacopy.datasets[0].data = data.map(cat => cat.kilogramos)
        console.log("grafico2", datacopy )
        setData(datacopy)
    }, [data, loading]);

    return (
			<section>
                {(periodo && periodo !== "")?
				<header>
					<h6>Tu reciclaje del periodo</h6>
					<h5 className="text-uppercase">{periodo}</h5>
                </header>
                :
                ""
                }
                {(loading)?
				<Spinner />
                :
                <div>
                    <div style={{position:"relative"}}>
                        <Doughnut key={Math.random} data={resumenCategoriaPeriodo} options={options} height={120} />
                    </div>
                    <div className="text-center" style={{marginTop:"-30%"}}>
                        <span>Total</span>
                        {(data)?
                        <span style={{ fontWeight: 'bold', display: 'block', fontSize: '3em' }}>
                            {Number(data.map(cat=>cat.kilogramos).reduce(function (acc, val) { return acc + val; }, 0)).toLocaleString('es-CL', {maximumFractionDigits: 1})}
                        <span style={{ fontSize: '1.5rem' }}>KG</span></span>
                        :
                        ""
                        }
                        
                    </div>
                </div>
                }
				

				

			</section>
    );
};

const RetiroChartBar = ({ data, periodo, loading= false}) => {
    
    const ref = React.createRef();
    const base = {
            datasets: [{
                label: 'Total del mes',
                backgroundColor: '#59de8d',
                hoverBackgroundColor: '#14AD50',
                barPercentage: 0.3,
                data: []
            }],
            labels: []
        }
    const options = {
        maintainAspectRatio: false,
        title: {
            display: false,
            text: 'Total del mes'
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    display: false
                }
            }],
            yAxes: [{
                gridLines: {
                    display: false
                },
                ticks: {
                    display: false
                }
            }]
        },
        tooltips: {
            backgroundColor: '#000',
            titleAlign: 'center',
            titleFontColor: '#fff',
            titleFontSize: 10,
            titleFontStyle: "normal",
            bodyAlign: "center",
            bodyFontColor: '#fff',
            bodyFontSize: 12,
            displayColors: false,
            bodyFontStyle: 'bold',
            borderColor: '#000',
            borderWidth: 2,
            cornerRadius: 1,
            xPadding: 15,
            yPadding: 10,
            callbacks: {
                title: () => ("TOTAL DEL MES"),
                label: function(tooltipItem) {
                    var label = tooltipItem.yLabel || '';
                    if (label) {
                        label = label.toLocaleString('es-CL', {maximumFractionDigits: 1}) + ' KG';
                    }
                    return label;
                }
            }
        }
    }
        
    const [resumenMes, setDataMes] = useState({})

    useEffect(() => {
        let datacopy = Object.assign({}, base)
        datacopy.labels = Object.keys(data).reverse()
        datacopy.datasets[0].data = Object.values(data).reverse()
        console.log("grafico bar", datacopy )
        setDataMes(datacopy)
    }, [data, loading]);

    return (
			<section>
				<header className="tabla-header border-b pb-1">
					<h6>Tu reciclaje de los</h6>
					<h5 className="text-uppercase">Últimos 13 meses</h5>
				</header>
                {(loading)?
				<Spinner />
                :
                <div>
                    <Bar data={resumenMes} options={options} height={200} />
                </div>
                }
			</section>
    );
};

export { RetiroChartDonuts, RetiroChartBar };