import React, { useEffect, useState, createRef  } from 'react'
import Spinner from './Spinner'
import TablaBase from "../components/TablaBase";
import { FormatoFecha } from '../utils/momentUtils'
import { Button, Accordion, Card } from "react-bootstrap";

const RetiroDetalle = ({ data, periodo="Actual", loading=false, imprimirInforme}) => {
	const [activeKey, setActive] = useState(false)

	const refCard = createRef()

    useEffect(() => {
		setActive(imprimirInforme);
		refCard.current.click();
    }, [imprimirInforme]);

    useEffect(() => {
		setActive(imprimirInforme);
    }, []);

	const columns = [{
		dataField: 'id',
		text: 'ID',
		hidden: true
	  	},{
		dataField: 'fecha_retiro',
		text: 'RETIRO',
		formatter: (cellContent, row) => {
		  return (
			<span><FormatoFecha fecha={row.fecha_retiro}/></span>
		  );
		},
		sort: true,
		type: 'date'
	  	}, {
		dataField: 'numero_guia',
		text: 'N° DE GUÍA'
	  	}, {
		dataField: 'nombre_categoria',
		text: 'CATEGORÍA'
	  	}, {
		dataField: 'descripcion',
		text: 'DESCRIPCiÓN',
		}, {
		dataField: 'kilogramos_retirados',
		text: 'KG RETIRADOS'
	}];
    return (
			<div className="retiro-tabla tabla-naranja">								
			{(loading)?
			<Spinner />
			:
			<Accordion>
				<Card>
					<Accordion.Toggle ref={refCard} as={Card.Header} eventKey="0" onClick={() => setActive(!activeKey)}>
					<div className="">
						<h6>Detalle del periodo</h6>
						<h5 className="text-uppercase">{periodo} <i className={(activeKey)?"fas fa-minus":"fas fa-plus"}></i></h5>
					</div>
					</Accordion.Toggle>
					<Accordion.Collapse eventKey="0">
						<Card.Body className="p-0">
							<TablaBase
							data={ data }
							columns={ columns }
							enCarga={ loading }
							busqueda={false}
							descarga={false}
							ocultarCantidadPorPagina={true}
							cantidadPorPagina={500}
							/>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
			</Accordion>
			}
			</div>
    );
};

export default RetiroDetalle;