import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fechaAtexto, fechaActual } from '../utils/momentUtils'

import { certificadosPeriodoEdificio, informesPeriodoEdificio } from '../store/actions/certificados'
import Informes from '../components/Informes'
import Certificados from '../components/Certificados'
import Bibliografia from '../components/Bibliografia'

const CertificadosInf = ( {mesP = fechaAtexto(fechaActual(), "MMYYYY"), setTitulo }) => {
    const dispatch = useDispatch()
    
    const edificio = useSelector(state => (state)?state.app.edificio:null)
    const fechas_informes = useSelector(state => (state)?state.certificados.fechas_informes:null)
    const certificadosEmpresa = useSelector(state => (state)?state.app.certificados:null)
    const certificados = useSelector(state => (state)?state.certificados.lista:null)
    const loadingCertificados = useSelector(state => state.certificados.loading)
    const bibliografias = useSelector( state => state.app.bibliografias )
    const contenidos = useSelector( state => state.app.contenidos )
    
    useEffect(() => {
        if(edificio){
            dispatch(certificadosPeriodoEdificio(edificio.id))
            dispatch(informesPeriodoEdificio(edificio.id))
        }
        // fin calcular los totales por mes

        // setea el titulo de pestaña
        setTitulo("Gestion B - Certificados")
    }, [edificio]);
    return (
        <>
			<div className="d-flex flex-wrap">
                <div className="p-1 w-100">
                    <div key="informes" className="p-0"><Informes key={Math.random} data={fechas_informes.reverse()} loading={loadingCertificados} /></div>
                </div>
                {(certificados)?
                <div className="d-flex flex-wrap">
                    <div key="certificados_2" className="col-sm-12 col-md-6 p-1 pb-2">
                        <Certificados key={Math.random}
                        data={ certificados.filter(cert => cert.tipo_certificado.id === "2") }
                        loading={loadingCertificados} titulo={<span>Informe de Valorización<br/>y Ecovalencias anuales</span>}
                        activo = {certificadosEmpresa.filter(cert => cert.id === "2").length > 0}
                        contenidoNoAccesible = {contenidos.filter(con => con.id === "2")[0]}
                        />
                    </div>
                    <div key="certificados_1" className="col-sm-12 col-md-6 p-1 pb-2">
                        <Certificados key={Math.random}
                        data={ certificados.filter(cert => cert.tipo_certificado.id === "1") }
                        loading={loadingCertificados} titulo={<span>Informe de Disposición final<br/>de residuos peligrosos (RESPEL)</span>}
                        activo = {certificadosEmpresa.filter(cert => cert.id === "1").length > 0}
                        contenidoNoAccesible = {contenidos.filter(con => con.id === "1")[0]}
                        />
                    </div>
                    <div key="certificados_4" className="col-sm-12 col-md-6 p-1 pb-2">
                        <Certificados key={Math.random}
                        data={ certificados.filter(cert => cert.tipo_certificado.id === "4") }
                        loading={loadingCertificados} titulo={<span>Informe de Medición de<br/>Huella de Carbono</span>}
                        activo = {certificadosEmpresa.filter(cert => cert.id === "4").length > 0}
                        contenidoNoAccesible = {contenidos.filter(con => con.id === "4")[0]}
                        />
                    </div>
                    <div key="certificados_3" className="col-sm-12 col-md-6 p-1 pb-2">
                        <Certificados key={Math.random}
                        data={ certificados.filter(cert => cert.tipo_certificado.id === "3") }
                        loading={loadingCertificados} titulo={<span>Informe de Evaluación de Sustentabilidad<br/>Corporativa para optar a Certificaciones</span>}
                        activo = {certificadosEmpresa.filter(cert => cert.id === "3").length > 0}
                        contenidoNoAccesible = {contenidos.filter(con => con.id === "3")[0]}
                        />
                    </div>
                </div>
                :
                ""
                }
                <div className="p-1 w-100 mb-2">
                    <div key="biblio" className="p-0"><Bibliografia key={Math.random} data={bibliografias} /></div>
                </div>
			</div>
        </>
    )
};

export default CertificadosInf;