import React from 'react'
import { Link } from 'gatsby'
import MarkdownView from 'react-showdown'

const RevisaCertificados = ({contenido=null}) => {
    return (
        <div className="revisa-certificados box-white">
            <div className="tabla-header border-b pb-1">
                <h6>Revisa</h6>
                <h5 className="text-uppercase">Tus certificados</h5>
            </div>                
            {(contenido)?
                <div className="p-3">
                    <div><MarkdownView markdown={contenido.contenido} /></div>
                    <div className="text-center p-3">
                        <Link className="btn-default btn-block p-2" to="/app/certificados" ><span className="text-uppercase font-weight-bold pr-2">Ir al centro de certificados</span></Link>
                    </div>
                </div>
            :
            ""
            }
        </div>
    );
};

export default RevisaCertificados;