import React from "react";
import { Spinner, Button } from "react-bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport  } from 'react-bootstrap-table2-toolkit';
import {isMobile} from 'react-device-detect';

const { SearchBar } = Search;
// const { ExportCSVButton } = CSVExport;

const ExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    
    return (
        <Button variant="secondary" className="pull-right" size="sm" onClick={ handleClick }><i className="flaticon2-download"></i> Descarga CSV</Button>
    );
  };

const TablaBase = ({data, columns, botonAdicional, enCarga, busqueda=true, descarga=true, ocultarCantidadPorPagina=false, cantidadPorPagina=10, responsive = true }) => {
    const optionsPaginacion = {
        // pageStartIndex: 0,
        sizePerPage: cantidadPorPagina,
        hideSizePerPage: ocultarCantidadPorPagina,
        hidePageListOnlyOnePage: true,
        nextPageText: (isMobile)?">":"Siguiente",
        prePageText: (isMobile)?"<":"Anterior",
        alwaysShowAllBtns: (isMobile)?false:true
      };
    return(
        <>
        {(data)?            
        <ToolkitProvider
        keyField='id'
        data={ data }
        columns={ columns }
        search
        exportCSV
        >
        {
            props => (
            <div>
                {
                (!busqueda && !descarga)?
                ""
                :
                <>
                <div className="row justify-content-between">
                    <div className="col-3">
                        {(busqueda)?
                        <SearchBar
                        { ...props.searchProps }
                        className="form-control-sm form-control"
                        // style={ { color: 'white' } }
                        placeholder="Buscar"
                        />
                        :
                        ""}
                    </div>
                    
                    <div className="col-4">
                        {/* <ExportCSV { ...props.csvProps } /> */}
                    </div>
                    
                    <div className="col-4">
                    &nbsp;
                        {(descarga)?<ExportCSV { ...props.csvProps } />:""}
                        &nbsp;
                        { botonAdicional }
                        &nbsp;
                    </div>
                </div>                
                <hr />
                </>
                }
                <BootstrapTable
                { ...props.baseProps}
                classes={(isMobile && responsive)?"table-responsive":""}
                bootstrap4
                pagination={ paginationFactory(optionsPaginacion) }
                noDataIndication={(enCarga)?<div className="d-flex justify-content-center">Cargando datos <Spinner animation="grow" variant="success" /></div>:"No se encontraron datos"}
                hover
                condensed
                defaultSortDirection="asc"
                bordered={false}
                />
            </div>
            )
        }
        </ToolkitProvider>
        :
        ""
        }
        </>
    )
}

export default TablaBase;