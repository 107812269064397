import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Modal } from 'react-bootstrap';
import Spinner from './Spinner'
import TablaBase from "../components/TablaBase";
import { FormatoFecha } from '../utils/momentUtils'
import { Button } from "react-bootstrap";
import { download } from '../utils/utils'

const TicketsPeriodo = ({ data, periodo="Actual", loading=false}) => {
	const rutaArchivoBase = process.env.STRAPI_URL
	
	const [showTicket, setShowTicket] = useState(false);
	const [imagen, setImagen] = useState("");
	const [ticket, setTicket] = useState({});
	const handleCloseTicket = () => setShowTicket(false);
	const handleShowTicket = () => setShowTicket(true);

	const muestraTicket = (ticket) => {
		setImagen(rutaArchivoBase+ticket.comprobante.url)
		setTicket(ticket)
		handleShowTicket()
	}

	const columns = [{
			dataField: 'id',
			text: 'ID',
			hidden: true
	  	},{
			dataField: 'numero_ticket',
			text: 'N° Ticket'
		}, {
			dataField: 'fecha_entrega',
			text: 'Entrega',
			formatter: (cellContent, row) => {
			return (
				<span><FormatoFecha fecha={row.fecha_entrega}/></span>
			)},
			sort: true,
			type: 'date'
		}, {
			dataField: 'kilogramos',
			text: 'Kg del periodo'
		},{
			dataField: "",
			text: "Comprobante",
			formatter: (cellContent, row) => {
			return (
				<div className="text-left font-weight-bold">
				<Link to="#" onClick={() => muestraTicket(row)}>Ver</Link>

				{/* Botones redondos */}
				{/* <a href={rutaArchivoBase+row.comprobante.url} target="_blank" className="button"><span className="fas fa-eye"></span></a>
				&nbsp;
				<Button onClick={() => download(rutaArchivoBase+row.comprobante.url, row.comprobante.name)} target="_blank"><span className="fas fa-download"></span></Button> */}
				</div>
			);
			}
	  }];	
    return (
			<div>
				<div className="box-white tickets-tabla tabla-naranja">
					<div className="tabla-header pb-1">
						<h6>Tus tickets</h6>
						<h5 className="text-uppercase">{periodo}</h5>
					</div>
					
					{(loading)?
					<Spinner />
					:
					<TablaBase
					data={ data }
					columns={ columns }
					enCarga={ loading }
					busqueda={false}
					descarga={false}
					ocultarCantidadPorPagina={true}
					cantidadPorPagina={5}
					/>
					}
				</div>
				
				<Modal key="modTicket" className="modal-ticket" show={showTicket} onHide={handleCloseTicket} size="md" centered>
					<Modal.Header closeButton className="border-b">
						<div>
							<div className="col-12 text-uppercase"><h6>Ticket {ticket.numero_ticket}</h6></div>
							
							<div className="sub-titulo col-12 text-uppercase"><span><FormatoFecha fecha={ticket.fecha_entrega} /></span></div>
						</div>
					</Modal.Header>
					<Modal.Body className="text-center">
						<div className="box-white overflow-auto">
							<img className="img-ticket" src={imagen} />
						</div>
					</Modal.Body>
					<Modal.Footer className="pt-0">
					<Button className="as-link text-uppercase font-weight-bold" onClick={() => download(imagen, ticket.comprobante.name)} target="_blank">Descargar</Button>
					</Modal.Footer>
				</Modal>
			</div>
    );
};

export default TicketsPeriodo;