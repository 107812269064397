import React, { useEffect, useState, useRef  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fechaAtexto, textoAFecha, InicioMesFecha, FinMesFecha, fechaActual } from '../utils/momentUtils'
import * as utils from '../utils/utils'
import * as html2canvas from 'html2canvas'
import { Spinner } from "react-bootstrap";

import { RetiroChartDonuts, RetiroChartBar } from '../components/RetiroChart'
import RetiroTabla from '../components/RetiroTabla'
import RetiroDetalle from '../components/RetiroDetalle'
import ConsejoVerde from '../components/ConsejoVerde'
import Comentarios from '../components/Comentarios'
import TicketsPeriodo from '../components/TicketsPeriodo'
import Bibliografia from '../components/Bibliografia'
import Ecovalencias from '../components/Ecovalencias'
import RevisaCertificados from '../components/RevisaCertificados'


import { setearPeriodo } from '../store/actions/app'
import { retirosPeriodoEdificio } from '../store/actions/retiros'
import { comentariosPeriodoEdificio } from '../store/actions/comentarios'
import { ticketsPeriodo } from '../store/actions/tickets'
import { calculaEcovalencias } from '../store/actions/ecovalencias'


const Dashboard = ({mesP = fechaAtexto(fechaActual(), "MMYYYY"), setTitulo}) => {
    const dispatch = useDispatch();
    const auth = useSelector(state => (state)?state.auth:null);
    const edificio = useSelector(state => (state)?state.app.edificio:null);
    const categorias = useSelector(state => (state)?state.app.categorias:null);

    const dashboardRef = useRef();
  
    const user = auth.user || null
    const edificiosUsuario = user.edificios
    const isAuthenticated = auth.loggedIn || false
    
    const [isLoading, setIsLoading] = useState(false)
    const [periodo, setPeriodo] = useState( fechaAtexto(textoAFecha(mesP, "MMYYYY"), "MMMM YYYY" ) )

    const resumenRetiros = useSelector( state => state.retiros.resumen )
    const listaRetiros = useSelector( state => state.retiros.lista )
    const retiros13Meses = useSelector( state => state.retiros.retiros13Meses )
    // const retirosTotales = useSelector( state => state.retiros.retirosTotales )    
    const loadingResumen = useSelector( state => state.retiros.loadingResumen )
    const consejos = useSelector( state => state.app.consejos )
    const comentarios = useSelector( state => state.comentarios.lista )
    const loadingComentarios = useSelector( state => state.comentarios.loading )
    const tickets = useSelector( state => state.tickets.lista )
    const bibliografias = useSelector( state => state.app.bibliografias )
    const contenidos = useSelector( state => state.app.contenidos )
    const ecovalencias = useSelector( state => state.ecovalencias.ecovalencias )
    const loadingEcovalencias = useSelector( state => state.ecovalencias.loading )
    console.log(textoAFecha(mesP, "MMYYYY"))

    const [resumenRetiroMes, setRetiroMes] = useState({})
    const [imprimirInforme, setImprimirInforme] = useState(false)

    useEffect(() => {
        // limpia objetos totales mensuales
        let resumenRetirosMes = {}
        if(retiros13Meses){
            retiros13Meses.map(retiro =>{
                let fecha = fechaAtexto( textoAFecha(retiro.periodo, "MMYYYY") , "MMMYY")
                fecha = utils.capitalizeFirstLetter(fecha)
                if(retiro.visible){
                    resumenRetirosMes[fecha] = retiro.kilogramos
                }
            })
            console.debug(resumenRetirosMes)
            setRetiroMes(resumenRetirosMes)
        }
        // fin totales por mes
    }, [retiros13Meses]);

    const descargaInforme = (periodo) => {
        setImprimirInforme(true);
        window.onscroll = function () { window.scrollTo(0, 0); }
        setTimeout(() => {
            const input = document.getElementById('dashboard')
            // const input = dashboardRef.current
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png')
                    utils.download(imgData, `informe_${periodo}.png`)
                    // console.log(imgData)
                    setImprimirInforme(false)
                    window.onscroll = function () {}
                });
          }, 300);
    };    

    useEffect(() => {
        if(edificio){
            setPeriodo( fechaAtexto(textoAFecha(mesP, "MMYYYY"), "MMMM YYYY" ) )
            
            const fechaIni = InicioMesFecha(textoAFecha(mesP, "MMYYYY"))
            const fechaFin = FinMesFecha(textoAFecha(mesP, "MMYYYY"))
            console.debug(fechaIni, fechaFin)

            const buscar = (ini, fin, edificioSel) => {
            // dispatch( retirosPeriodoResumen(edificio.id, ini, fin, categorias) )
                dispatch( setearPeriodo( mesP ) )
                dispatch( retirosPeriodoEdificio(edificioSel.id, ini, fin) )
                dispatch( comentariosPeriodoEdificio(edificioSel.id, ini, fin) )
                dispatch( ticketsPeriodo(edificioSel.id, ini, fin) )
                dispatch( calculaEcovalencias(edificioSel.id, fin) )
            }
            buscar(fechaIni, fechaFin, edificio)
        }
        // fin calcular los totales por mes
        
    }, [mesP, edificio]);

    
    useEffect(() => {
        // setea el titulo de pestaña
        setTitulo(`Gestion B - Informe ${periodo}`)
    }, [periodo]);

    return(
        <>
			<div className={`d-flex flex-wrap ${(imprimirInforme)?"stop-scrolling":""}`} ref={dashboardRef} id="dashboard">
                <div className="d-flex flex-wrap p-0 w-100 box-white mb-3">
                    <div className="d-flex flex-wrap w-100 pt-2 pb-1 pl-3 pr-3 border-b" >
                        <div className="col-sm-12 col-md-9 p-0 ">
                            <h6>Tu reciclaje</h6>
                            <h5 className="text-uppercase">{periodo}</h5>
                        </div>
                        <div className="col-sm-12 col-md-3 text-right pt-2 pb-2 pr-0 pl-0">
                            <button className="btn btn-default btn-block" onClick={() => {setImprimirInforme(true);descargaInforme(periodo);} } style={{fontSize: "1rem"}}>
                                Descargar informe {(imprimirInforme)?<Spinner animation="grow" variant="light" size="sm" />:""}
                            </button>
                        </div>
                    </div>
                    <div key="graficoDonut" className="col-sm-12 col-md-8 d-none d-md-block p-0 pt-4"><RetiroChartDonuts key={Math.random} data={resumenRetiros} loading={loadingResumen} periodo={""} /></div>
                    <div key="tablaResumen" className="col-sm-12 col-md-4 p-0" style={{maxHeight: "430px"}}><RetiroTabla key={Math.random} data={resumenRetiros} loading={loadingResumen} periodo={""} /></div>
                </div>
				<div key="graficoBar" className="box-white p-0 mb-3 col-sm-12"><RetiroChartBar key={Math.random} data={resumenRetiroMes} loading={loadingResumen} /></div>
                <div key="eco" className="box-green p-0 col-sm-12 col-md-8 mb-3"><Ecovalencias ecovalencias={ecovalencias} contenidoModal={contenidos.filter(con => con.id === "5")[0]} loading={loadingEcovalencias} /></div>
                <div key="consejo" className="p-0 col-sm-12 col-md-4 p-left-6">
                    <div className="d-flex flex-wrap w-100 p-0" >
                        <div className="col-12 mb-3 p-0">
                            <RevisaCertificados contenido={contenidos.filter(con => con.id === "6")[0]} />
                        </div>
                        <div className="col-12 mb-3 p-0">
                            <ConsejoVerde consejos={consejos} loading={loadingResumen} />
                        </div>
                    </div>
                </div>
                <div key="comentarios" className="box-transparent col-sm-12 col-md-4 mb-3"><Comentarios comentarios={comentarios} imprimirInforme={imprimirInforme} loading={loadingComentarios} /></div>
				<div key="tickets" className="p-0 mb-3 col-sm-12 col-md-8 d-none d-md-block p-left-6"><TicketsPeriodo key={Math.random} data={tickets} periodo={periodo} loading={loadingResumen} /></div>
				{/* <div key="hablemos" className="p-0 col-sm-12 col-md-4 p-left-6"><Hablemos /></div> */}
				<div key="retiroDetalle" className="box-white p-0 mb-3 col-sm-12"><RetiroDetalle key={Math.random} data={listaRetiros} loading={loadingResumen} periodo={periodo} imprimirInforme={imprimirInforme} /></div>
				<div key="biblio" className="box-white col-12 p-0 mb-3"><Bibliografia key={Math.random} data={bibliografias} loading={loadingResumen} /></div>
                {/* boton test de funciones */}
                {/* <button className="btn btn-default btn-block" onClick={() => {setImprimirInforme(true);} } style={{fontSize: "1rem"}}>
                    ver
                </button> */}
			</div>
        </>
    )
}

export default Dashboard