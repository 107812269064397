import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux';
import { navigate } from "gatsby"
import { Router, Route } from "@reach/router"
import { apiGraphql } from '../axios/axios'
import DefaultLayout from "../layouts/DefaultLayout"
import Dashboard from "../securePage/Dashboard"
import CertificadosInf from "../securePage/Certificados"
import * as utils from '../utils/utils'
// import { menu } from '../store/actions/useAuth'
const Private = ({ component: Component, location, ...rest }) => {
  return <Component {...rest} />;
};

const App = ({ location }) => {
  const user = useSelector(state => state.auth)
  const [locationPath, setlocationPath] = useState(location.pathname)

  utils.setupAxios(apiGraphql, user)
  
  const [title, setTitulo] = useState("Gestion B")

  console.debug("location:", location)

  useEffect(() => {
    if (!user.loggedIn) {
      navigate('/login');
    }
    setlocationPath(location.pathname)
  }, [location]);
  
  return (
    <>
    {(!user.loggedIn)?
      ""
      :
      <DefaultLayout title={title} locationPath={locationPath} isAuthenticated={user.loggedIn}>
        <Router basepath="/app">
          <Dashboard path="/dashboard/:mesP" setTitulo={setTitulo} />
          <Dashboard path="/dashboard" setTitulo={setTitulo} />
          <CertificadosInf path="/certificados/:mesP" setTitulo={setTitulo} />
          <CertificadosInf path="/certificados/" setTitulo={setTitulo} />
        </Router>
      </DefaultLayout>
    }
    </>
  )
}
export default App