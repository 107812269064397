import React from 'react'
import { Link } from 'gatsby'
import { Button } from "react-bootstrap";
import Spinner from './Spinner'
import TablaBase from "../components/TablaBase"
import { download } from '../utils/utils'
import { FormatoFecha } from '../utils/momentUtils'

const Certificados = ({ data, titulo, loading=false, activo = true, contenidoNoAccesible=null}) => {
    
    const rutaArchivoBase = process.env.STRAPI_URL

	const columns = [{
            dataField: 'id',
            text: 'ID',
            hidden: true
        },{
			dataField: 'fecha_emision',
			text: 'Fecha emisión',
			sort: true,
			hidden: false,
			formatter: (cellContent, row) => {
			return (
				<span><FormatoFecha fecha={row.fecha_emision}/></span>
			)}
	  	},{
			dataField: "",
			text: "Certificado",
			formatter: (cellContent, row) => {
                console.debug(row)
			return (
                <div className="">
                <Link className="text-left" to={rutaArchivoBase+row.documento.url} target="_blank" ><span className="text-uppercase font-weight-bold pr-2">Ver</span></Link>
				&nbsp;
                <Button className="as-link text-uppercase font-weight-bold" onClick={() => download(rutaArchivoBase+row.documento.url, row.documento.name)} target="_blank">Descargar</Button>
				</div>
			);
			}
	  }];
    return (
        <div>
            <div className="certificados-tabla tabla-naranja">
                <div className={`tabla-header pb-1 ${!activo?"disabled":""}`} >
                    <h6>Certificados</h6>
                    <h5>{titulo}</h5>
                </div>
                
                {(loading)?
                <Spinner />
                :
                <>
                {activo?
                <TablaBase
                data={ data }
                columns={ columns }
                enCarga={ loading }
                busqueda={false}
                descarga={false}
                ocultarCantidadPorPagina={true}
                cantidadPorPagina={5}
                />
                :
                (contenidoNoAccesible)?
                    <div className="p-3" style={{color:contenidoNoAccesible.color_texto}}>
                        <h6>{contenidoNoAccesible.titulo}</h6>
                        <div>{contenidoNoAccesible.contenido}</div>
                        <div className="text-center p-3">
                            <Link className="text-left btn-default p-2" to={contenidoNoAccesible.link} target="_blank" ><span className="text-uppercase font-weight-bold pr-2">contrátalo aquí</span></Link>
                        </div>
                    </div>
                :
                ""
                }
                </>
                }
            </div>
        </div>
    );
};

export default Certificados;